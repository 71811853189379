import React, { Suspense, useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { useDynamicNavTheme } from '../../hooks/useDynamicNavTheme';
import { useScrollIgnoreCheck } from '../../providers/StickyScrollHelperProvider'

import CustomNavLink from '../../components/CustomNavLink/CustomNavLink';

import './NavigationBar.scss';

const LauwerParticles = React.lazy(() => import('../../components/LauwerParticles'));

const NavigationBar = ({ pages }) => {
  const { i18n } = useTranslation();
  const { navTheme } = useDynamicNavTheme();
  const { ignoreScroll } = useScrollIgnoreCheck();
  const [navLanguage, setNavLanguage] = useState(i18n.language);

  const ignoreScrollRef = useRef(ignoreScroll);
  const navigate = useNavigate();

  useEffect(() => {
    ignoreScrollRef.current = ignoreScroll;
  }, [ignoreScroll]);

  // Handle language change
  const handleLanguageChange = (_, newLanguage) => {
    // Bad language, go to en home
    if(newLanguage === undefined) {
      i18n.changeLanguage('en');
      setNavLanguage('en');
      navigate(pages[0].to);
      return;
    }

    // Change language
    if (newLanguage !== null) {
      const currentPath = window.location.pathname;
      const newPath = currentPath.replace(`/${navLanguage}`, `/${newLanguage}`);
      
      setNavLanguage(newLanguage);
      i18n.changeLanguage(newLanguage);
      
      navigate(newPath);
    }
  };

  // Ensure i18n language matches the language in the URL
  useEffect(() => {
    // Use a regular expression to extract the language code from the URL
    const langRegex = /\/([a-z]{2})\//;
    const match = window.location.pathname.match(langRegex);
    const urlLang = match ? match[1] : undefined;

    // ensure the language in the URL is valid
    if (urlLang === undefined || (urlLang !== 'en' && urlLang !== 'fr')) {
      handleLanguageChange(null, i18n.language);
      return;
    }

    // If the language in the URL is different from the current language, change the language
    if (urlLang && i18n.language !== urlLang) {
      handleLanguageChange(null, urlLang);
    }
  }, []);

  // Navbar behaviour (smart sticky)
  const [shouldFixNavbar, setShouldFixNavbar] = useState(false);
  const [triggerAnimation, setTriggerAnimation] = useState(false);
  let lastScrollY = window.scrollY;

  // Check if passive event listeners are supported
  let passiveSupported = false;
  try {
    const options = {
      get passive() {
        passiveSupported = true;
        return false;
      }
    };

    window.addEventListener("test", null, options);
    window.removeEventListener("test", null, options);
  } catch (err) {
    passiveSupported = false;
  }

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY < lastScrollY && !shouldFixNavbar && !ignoreScrollRef.current) {
        setShouldFixNavbar(true);
        setTimeout(() => setTriggerAnimation(true), 10);
      } else {
        setTriggerAnimation(false);
        setShouldFixNavbar(false);
      }
      lastScrollY = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll, { passive: passiveSupported });

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className={`nav-container ${shouldFixNavbar ? 'nav-sticky-on' : ''} ${triggerAnimation ? 'nav-sticky-animation' : ''}`}>
      <Suspense>
        <LauwerParticles id={`nav-particles`} className={`${navTheme === 'nav-back-transparent' ? `hide-particles` : ``}`} />
      </Suspense>
      <nav className={`navbar ${navTheme}`}>
        <div className="nav-items">
          <ul>
            {pages.map((page, index) => (
              <li key={index}>
                <CustomNavLink to={`/${navLanguage}${page.to}`}>{i18n.t(`page_title_${page.label.toLowerCase()}`)}</CustomNavLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="toggle-button-group">
          <ToggleButtonGroup
            value={navLanguage}
            exclusive
            onChange={handleLanguageChange}
            aria-label="Language"
          >
            <ToggleButton value="en" aria-label="English">
              EN
            </ToggleButton>
            <ToggleButton value="fr" aria-label="French">
              FR
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </nav>
    </div>
  );

}

export default NavigationBar;

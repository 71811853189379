import { createTheme } from '@mui/material/styles';

// Function to create a theme with custom color schemes
export const getTheme = (mode) => createTheme({
  palette: {
    mode, // 'light' or 'dark'
    ...(mode === 'light'
      ? {
          // palette values for light mode
          background: {
            default: '#f7f6f3', // Custom light background color
            paper: '#202020', // Custom light paper color
          },
          text: {
            primary: '#2e2e2e', // Custom light text primary color
            secondary: '#5a5a5a', // Custom light text secondary color
          },
          secondary: {
            main: '#aaaaaa', // Custom light border color
          }
        }
      : {
          // palette values for dark mode
          background: {
            default: '#121212', // Custom dark background color
            paper: '#202020', // Custom dark paper color
          },
          text: {
            primary: '#e8e8e8', // Custom dark text primary color
            secondary: '#a1a1a1', // Custom dark text secondary color
          },
        }),
  },
});

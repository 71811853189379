import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the type of the context value
interface ScrollContextType {
    ignoreScroll: boolean;
    setIgnoreScroll: (value: boolean) => void;
  }

// Create a context
const StickyScrollHelperContext = createContext<ScrollContextType>({
    ignoreScroll: false,
    setIgnoreScroll: () => {},
  });

// Create a provider component
export const StickyScrollHelperProvider = ({ children }: { children: ReactNode }) => {
  const [ignoreScroll, setIgnoreScroll] = useState(false);

  return (
    <StickyScrollHelperContext.Provider value={{ ignoreScroll, setIgnoreScroll }}>
      {children}
    </StickyScrollHelperContext.Provider>
  );
};

// Create a hook to use the context
export const useScrollIgnoreCheck = () => useContext(StickyScrollHelperContext);
import './i18n';

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { HelmetProvider } from 'react-helmet-async';

import CssBaseline from '@mui/material/CssBaseline';

import { ThemeProvider } from '@mui/material/styles';
import { getTheme } from './theme';

import { DataLoadService } from '@wernerlaurence/lauwer-data-system';
import { DataProvider } from '@wernerlaurence/lauwer-data-system';

import { StickyScrollHelperProvider } from './providers/StickyScrollHelperProvider';
import { ParticlesProvider } from './providers/ParticlesProvider';

import '@wernerlaurence/lauwer-components/dist/main.css';
import './index.css';

const ProviderWrappedApp = () => {
  // Detects the user's preference for a light or dark theme
  const theme = getTheme('light');

  // Set default data loader functions
  const defaultDataLoader = async (path) => DataLoadService.loadDataFromPath(path);
  const defaultDataListLoader = async (path) => DataLoadService.loadDataFromPath(path);

  return (
    <HelmetProvider>
      <ParticlesProvider>
        <StickyScrollHelperProvider>
          <DataProvider dataLoader={defaultDataLoader} dataListLoader={defaultDataListLoader}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <App />
            </ThemeProvider>
          </DataProvider>
        </StickyScrollHelperProvider>
      </ParticlesProvider>
    </HelmetProvider>
  );
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ProviderWrappedApp />
  </React.StrictMode>
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// Service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      registration.addEventListener('updatefound', () => {
        const newWorker = registration.installing;
        newWorker.addEventListener('statechange', () => {
          if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
            console.log('New content is available; please refresh.');
          }
        });
      });
    });
  });
}
// Service worker refresh
setInterval(() => {
  navigator.serviceWorker.getRegistration().then(registration => {
    registration.update();
  });
}, 1 * 60 * 60 * 1000);

import React, { Suspense, useEffect, useState } from 'react';

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { CircularProgress } from '@mui/material';

import NavigationBar from './non_data_components/NavigationBar/NavigationBar';

const Profile = React.lazy(() => import('./pages/Profile/Profile'));
const Portfolio = React.lazy(() => import('./pages/Portfolio/Portfolio'));
const Contact = React.lazy(() => import('./pages/Contact/Contact'));
const Footer = React.lazy(() => import('./components/Footer/Footer'));


const pages = [
    { to: '/profile', label: 'Profile' },
    { to: '/portfolio', label: 'Portfolio' },
    { to: '/contact', label: 'Contact' },
];

function AppRouter() {
    const { i18n } = useTranslation();
    const [lang, setLang] = useState(i18n.language);

    useEffect(() => {
        setLang(i18n.language);
    }, [i18n.language]);


    return (
        <div>
            <Router>
                <NavigationBar pages={pages} />
                <Suspense fallback={<CircularProgress />}>
                    <Routes>
                        <Route path="/:lang/profile" element={<Profile />} />
                        <Route path="/:lang/portfolio" element={<Portfolio />} />
                        <Route path="/:lang/contact" element={<Contact />} />
                        <Route path="*" element={<h1>FOUR ZERO FOUR</h1>} />
                        <Route path="/" element={<Navigate replace={true} to={`/${lang}/profile`} />} />
                        <Route path="/profile" element={<Navigate replace={true} to={`/${lang}/profile`} />} />
                        <Route path="/portfolio" element={<Navigate replace={true} to={`/${lang}/portfolio`} />} />
                        <Route path="/contact" element={<Navigate replace={true} to={`/${lang}/contact`} />} />
                    </Routes>
                </Suspense>
            </Router>
            <Suspense fallback={<CircularProgress />}>
                <Footer />
            </Suspense>
        </div>
    );
}

export default AppRouter;
import { useState, useEffect, useCallback } from 'react';

let globalNavTheme = 'nav-back-transparent';
const listeners = new Set<() => void>();

const notifyListeners = () => {
  listeners.forEach(listener => listener());
};

export const useDynamicNavTheme = () => {
  const [theme, setNavTheme] = useState(globalNavTheme);

  useEffect(() => {
    const listener = () => {
      setNavTheme(globalNavTheme);
    };

    listeners.add(listener);
    return () => {
      listeners.delete(listener);
    };
  }, []);

  const changeNavTheme = useCallback((newTheme: string) => {
    globalNavTheme = newTheme;
    notifyListeners();
  }, []);

  return { navTheme: theme, changeNavTheme };
};

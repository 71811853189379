import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Library resources
import { libraryLocalizationResources } from '@wernerlaurence/lauwer-locales';

// App resources
import en from './locales/en.json';
import fr from './locales/fr.json';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: {
          ...libraryLocalizationResources.en,
          ...en
        }
      },
      fr: {
        translation: {
          ...libraryLocalizationResources.fr,
          ...fr
        }
      },
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });


// Set the language to the detected language if it is supported, 
// otherwise set it to English (hacky as hell - temp solution)
if (i18n.language.startsWith('en')) {
  i18n.changeLanguage('en');
}
else if (i18n.language.startsWith('fr')) {
  i18n.changeLanguage('fr');
}
else {
  i18n.changeLanguage('en');
}

export default i18n;

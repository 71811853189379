import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

// CustomLink component that scrolls to the top before navigating
const CustomNavLink: React.FC<{ to: string; children: React.ReactNode }> = ({ to, children }) => {
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    window.scrollTo(0, 0);
    
    navigate(to);
  };

  return (
    <NavLink to={to} onClick={handleClick}>
      {children}
    </NavLink>
  );
};

export default CustomNavLink;

import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';
import { Engine } from '@tsparticles/engine';
import { loadPolygonMaskPlugin } from '@tsparticles/plugin-polygon-mask';

// Define the context shape
interface ParticlesContextType {
    init: boolean;
}

// Create Context with a default value
const ParticlesContext = createContext<ParticlesContextType>({ init: false });

// Define the provider props type
interface ParticlesProviderProps {
    children: ReactNode;
}

// Provider Component
export const ParticlesProvider: React.FC<ParticlesProviderProps> = ({ children }) => {
    const [init, setInit] = useState<boolean>(false);

    useEffect(() => {
        if(init) return;

        initParticlesEngine(async (engine: Engine) => {
            setInit(true);
            await loadSlim(engine);
            await loadPolygonMaskPlugin(engine);
        });
    }, []);

    return (
        <ParticlesContext.Provider value={{ init }}>
            {children}
        </ParticlesContext.Provider>
    );
};

// Custom hook to use the particles context
export const useParticles = (): ParticlesContextType => useContext(ParticlesContext);
